<template>
  <validation-observer ref="simpleRules">
    <b-card>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label for="username">ชื่อผู้ใช้ <small class="text-danger">*(ใส่ตัวอักษร หรือตัวเลขอย่างน้อย 6 ตัวอักษร)</small></label>
              <validation-provider
                #default="{ errors }"
                name="Username"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกชื่อผู้ใช้ให้ถูกต้อง' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="username">รหัสผ่าน<small class="text-danger">*</small></label>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required"
              >
                <b-form-input
                  v-model="PasswordValue"
                  type="password"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="username">ยืนยันรหัสผ่าน<small class="text-danger">*</small></label>
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|confirmed:Password"
              >
                <b-form-input
                  v-model="passwordCon"
                  :state="errors.length > 0 ? false:null"
                  type="password"
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกรหัสผ่านให้ตรงกัน' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <label for="username">ชื่อ<small class="text-danger">*</small></label>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกชื่อให้ถูกต้อง' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="username">นามสกุล<small class="text-danger">*</small></label>
              <validation-provider
                #default="{ errors }"
                name="lastname"
                rules="required"
              >
                <b-form-input
                  v-model="lastname"
                  type="text"
                />
                <small class="text-danger">{{ errors[0] ? 'กรุณากรอกนามสกุลให้ถูกต้อง' : '' }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="username">เบอร์โทรศัพท์</label>
              <b-form-input
                v-model="tel"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label for="username">Line ID</label>
              <b-form-input
                v-model="line_id"
                type="text"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <hr>
      <div class="row mt-1 align-items-center">
        <div class="col-8">

          <h3 class="font-weight-bolder font-medium-3">
            ตั้งค่าเริ่มต้น<small class="text-danger">*</small>
          </h3>
        </div>
        <div class="col-4">
          <v-select
            v-model="copy_by"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            class="w-100"
            placeholder="คัดลอกจาก"
            :options="userOp"
          />
        </div>
      </div>

      <div class="d-flex">
        <b-button
          v-for="(tab, index) in tabs"
          :key="index"
          variant="outline-primary"
          class="mr-1"
          :class="{ active: currentTab === index }"
          @click="currentTab = index"
        >{{ tab }}
        </b-button>
      </div>

      <div
        v-show="currentTab === 0"
        class="mt-1"
      >
        <table class="table table-hover table-bordered">
          <colgroup>
            <col width="60%">
            <col width="40%">
          </colgroup>
          <thead>
            <tr>
              <th
                colspan="4"
                class="text-center"
              >
                ตั้งค่าเปิด/ปิดกลุ่มหวย
              </th>
            </tr>
            <tr class="text-center">
              <th>กลุ่มหวย</th>
              <th>เปิด/ปิด</th>
            </tr>
          </thead>
          <tbody>
            <tr style="background: #b4deffd9">
              <td>ตั้งค่าหวยทั้งหมด</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="huay_status"
                  value="1"
                  switch
                >
                  {{ huay_status ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
            <tr>
              <td>หวยหุ้นไทย</td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="huay_status"
                  value="1"
                  switch
                >
                  {{ excess ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-show="currentTab === 1"
        class="mt-1"
      >
        <table class="table table-hover table-bordered">
          <thead>
            <tr>
              <th
                colspan="5"
                class="text-center"
              >
                ตั้งค่าแบ่งหุ้น/เก็บของ
              </th>
            </tr>
            <tr class="text-center">
              <th>กลุ่มหวย</th>
              <th>ได้รับ</th>
              <th>เก็บของ(เข้าตัวเรา)</th>
              <th>เก็บของเกิน</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>หวยหุ้นไทย</td>
              <td class="text-center">
                {{ excess_percent }}%
              </td>
              <td>
                <b-form-select
                  v-model="share_agent"
                  :options="excessOp"
                />
              </td>
              <td>
                <b-form-select
                  v-model="excess_percent"
                  :options="excessOp"
                  :disabled="!excess"
                />
              </td>
              <td class="text-center">
                <b-form-checkbox
                  v-model="excess"
                  value="1"
                  switch
                >
                  {{ excess ? 'เก็บของเกิน' : 'ไม่เก็บของเกิน' }}
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div
        v-show="currentTab === 2"
        class="mt-1"
      >
        <div>
          <label
            for="set-groub"
            class="font-weight-bolder"
          >เลือกกลุ่มหวย</label>
          <div class="d-flex mb-1">
            <button class="btn btn-sm btn-primary mr-1">
              เลือกทั้งหมด
            </button>
            <button class="btn btn-sm btn-primary mr-1">
              ยกเลิกทั้งหมด
            </button>
            <button class="btn btn-sm btn-warning mr-1">
              ตั้งค่าทั้งหมด
            </button>
          </div>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="title"
            class="select-size-lg"
            :options="option"
          />
        </div>
        <div>
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            ตั้งค่าข้อมูลการแทงทั้งหมด
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead class="bg-primary thead-dark">
              <tr>
                <th
                  scope="col"
                  rowspan="2"
                  class="text-center align-middle"
                >
                  ประเภท
                </th>
                <th colspan="4">
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขหัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  ขั้นต่ำ
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุด
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อเลข
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อครั้ง
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                  >
                    ตั้งสูงสุด
                  </button>
                </th>
              </tr>
              <tr>
                <th class="text-center align-middle" />
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งล่าง
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวบน
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวบน
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
              <tr>
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งบน
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
                <td class="text-left py-2">
                  <input
                    type="number"
                    class="form-control"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            รัฐบาล
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead>
              <tr>
                <th
                  scope="col"
                  rowspan="2"
                  class="text-center align-middle"
                >
                  ประเภท
                </th>
                <th colspan="4">
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button>
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขหัว
                  </button>
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button>
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th>
              </tr>
              <tr>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  ขั้นต่ำ
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุด
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อเลข
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                >
                  สูงสุดต่อครั้ง
                </th>
              </tr>
              <tr>
                <th class="text-center align-middle" />
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-sm"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-sm"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-sm"
                  >
                </th>
                <th class="text-left py-2">
                  <input
                    type="number"
                    min="0"
                    class="form-control form-control-sm"
                  >
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__757"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__758"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__759"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__759"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวล่าง
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__760"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__761"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__762"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__762"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งล่าง
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__763"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__764"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__765"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__765"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr
                class="table-disabled"
                style="display: none;"
              >
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__766"
                      type="number"
                      disabled="disabled"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__767"
                      type="number"
                      disabled="disabled"
                      min="0"
                      max="10000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        10000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__768"
                      type="number"
                      disabled="disabled"
                      min="0"
                      max="10000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        10000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__768"
                      type="number"
                      disabled="disabled"
                      min="0"
                      max="10000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        10000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวโต๊ด
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__769"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__770"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__771"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__771"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  2 ตัวบน
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__772"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__773"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__774"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__774"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  3 ตัวบน
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__775"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__776"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__777"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__777"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr class="">
                <td
                  scope="row"
                  class="text-center align-middle"
                >
                  วิ่งบน
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__778"
                      type="number"
                      min="0"
                      max="1"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        1
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__779"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__780"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
                <td class="text-left py-2">
                  <div
                    role="group"
                    class="input-group input-group-sm"
                  >
                    <input
                      id="__BVID__780"
                      type="number"
                      min="0"
                      max="100000"
                      class="form-control"
                    >
                    <div class="input-group-append">
                      <div class="input-group-text">
                        100000
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        v-show="currentTab === 3"
        class="mt-1"
      >
        <div>
          <label
            for="set-groub"
            class="font-weight-bolder"
          >เลือกกลุ่มหวย</label>
          <div class="d-flex mb-1">
            <button class="btn btn-sm btn-primary mr-1">
              เลือกทั้งหมด
            </button>
            <button class="btn btn-sm btn-primary mr-1">
              ยกเลิกทั้งหมด
            </button>
            <button class="btn btn-sm btn-warning mr-1">
              ตั้งค่าทั้งหมด
            </button>
          </div>
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label="title"
            class="select-size-lg"
            :options="option"
          />
        </div>

        <!-- setting all -->
        <div class="mt-1">
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            ตั้งค่าอัตราจ่ายทั้งหมด
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead class="bg-primary text-dark">
              <tr>
                <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  อัตรจ่าย
                </th>
                <th
                  rowspan="2"
                  class="text-center align-middle"
                >
                  เปิด/ปิด
                </th> <th rowspan="2" /> <th colspan="7">
                  <button
                    type="button"
                    class="btn mr-1 tx-13 btn-primary btn-sm"
                  >
                    เลขท้าย
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขหัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลข 4-6 ตัว
                  </button><button
                    type="button"
                    class="btn mr-1 tx-13 btn-light btn-sm"
                  >
                    เลขคู่คี่
                  </button>
                </th></tr> <tr><th class="text-center">
                2 ตัวล่าง
              </th><th class="text-center">
                3 ตัวล่าง
              </th><th class="text-center">
                วิ่งล่าง
              </th><th class="text-center">
                3 ตัวโต๊ด
              </th><th class="text-center">
                2 ตัวบน
              </th><th class="text-center">
                3 ตัวบน
              </th><th class="text-center">
                วิ่งบน
              </th></tr>
            </thead> <tbody><tr><th
              scope="col"
              rowspan="3"
              class="text-center align-middle"
            >
              ตั้งค่าอัตราจ่ายทั้งหมด
            </th> <td
              rowspan="3"
              class="text-center align-middle"
            >
              <div class="custom-control custom-switch">
                <input
                  id="__BVID__462"
                  type="checkbox"
                  class="custom-control-input is-invalid"
                  value="true"
                ><label
                  class="custom-control-label"
                  for="__BVID__462"
                >ปิด</label>
              </div>
            </td> <td>จ่าย</td> <td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td></tr> <tr><td>คอม</td> <td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td><td class="py-2">
              <input
                type="number"
                class="form-control form-control-sm"
              >
            </td></tr></tbody>
          </table>
        </div>

        <div>
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            รัฐบาล
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead>
              <tr><th
                rowspan="2"
                class="text-center align-middle"
              >
                อัตรจ่าย
              </th> <th
                rowspan="2"
                class="text-center align-middle"
              >
                เปิด/ปิด
              </th> <th rowspan="2" /> <th
                colspan="7"
                width="70%"
              >
                <button
                  type="button"
                  class="btn mr-1 tx-13 btn-primary btn-sm"
                >
                  เลขท้าย
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลขหัว
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลข 4-6 ตัว
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลขคู่คี่
                </button>
              </th></tr> <tr><th class="text-center">
                2 ตัวล่าง
              </th><th class="text-center">
                3 ตัวล่าง
              </th><th class="text-center">
                วิ่งล่าง
              </th><th class="text-center">
                3 ตัวโต๊ด
              </th><th class="text-center">
                2 ตัวบน
              </th><th class="text-center">
                3 ตัวบน
              </th><th class="text-center">
                วิ่งบน
              </th></tr>
            </thead> <tbody><tr class="">
              <th
                scope="col"
                rowspan="3"
                class="text-center align-middle"
              >
                อัตราจ่ายเริ่มต้น
              </th> <td
                rowspan="3"
                class="text-center align-middle"
              >
                <div class="custom-control custom-switch">
                  <input
                    id="__BVID__431"
                    type="checkbox"
                    class="custom-control-input is-valid"
                    value="true"
                  ><label
                    class="custom-control-label"
                    for="__BVID__431"
                  >เปิด</label>
                </div>
              </td> <td>จ่าย</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__432"
                    type="number"
                    min="0"
                    max="90"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      90
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__433"
                    type="number"
                    min="0"
                    max="145"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      145
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__434"
                    type="number"
                    min="0"
                    max="4"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      4
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__435"
                    type="number"
                    min="0"
                    max="125"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      125
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__436"
                    type="number"
                    min="0"
                    max="90"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      90
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__437"
                    type="number"
                    min="0"
                    max="750"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      750
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__438"
                    type="number"
                    min="0"
                    max="3"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      3
                    </div>
                  </div>
                </div>
              </td>
            </tr> <tr class="">
              <td>คอม</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__439"
                    type="number"
                    min="0"
                    max="8"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      8
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__440"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__441"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__442"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__443"
                    type="number"
                    min="0"
                    max="8"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      8
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__444"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__445"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td>
            </tr></tbody><tbody><tr class="">
              <th
                scope="col"
                rowspan="3"
                class="text-center align-middle"
              >
                หวย 70
              </th> <td
                rowspan="3"
                class="text-center align-middle"
              >
                <div class="custom-control custom-switch">
                  <input
                    id="__BVID__446"
                    type="checkbox"
                    class="custom-control-input is-valid"
                    value="true"
                  ><label
                    class="custom-control-label"
                    for="__BVID__446"
                  >เปิด</label>
                </div>
              </td> <td>จ่าย</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__447"
                    type="number"
                    min="0"
                    max="70"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      70
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__448"
                    type="number"
                    min="0"
                    max="125"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      125
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__449"
                    type="number"
                    min="0"
                    max="4"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      4
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__450"
                    type="number"
                    min="0"
                    max="105"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      105
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__451"
                    type="number"
                    min="0"
                    max="70"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      70
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__452"
                    type="number"
                    min="0"
                    max="550"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      550
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__453"
                    type="number"
                    min="0"
                    max="3"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      3
                    </div>
                  </div>
                </div>
              </td>
            </tr> <tr class="">
              <td>คอม</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__454"
                    type="number"
                    min="0"
                    max="28"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      28
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__455"
                    type="number"
                    min="0"
                    max="33"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      33
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__456"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__457"
                    type="number"
                    min="0"
                    max="33"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      33
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__458"
                    type="number"
                    min="0"
                    max="28"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      28
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__459"
                    type="number"
                    min="0"
                    max="33"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      33
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__460"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td>
            </tr></tbody>
          </table>
        </div>

        <div>
          <h3 class="font-weight-bolder font-medium-3 mt-1">
            หวยรายวัน
          </h3>
          <table class="table table-bordered table-sm table-hover">
            <thead>
              <tr><th
                rowspan="2"
                class="text-center align-middle"
              >
                อัตรจ่าย
              </th> <th
                rowspan="2"
                class="text-center align-middle"
              >
                เปิด/ปิด
              </th> <th rowspan="2" /> <th
                colspan="7"
                width="70%"
              >
                <button
                  type="button"
                  class="btn mr-1 tx-13 btn-primary btn-sm"
                >
                  เลขท้าย
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลขหัว
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลข 4-6 ตัว
                </button><button
                  type="button"
                  class="btn mr-1 tx-13 btn-light btn-sm"
                >
                  เลขคู่คี่
                </button>
              </th></tr> <tr><th class="text-center">
                2 ตัวล่าง
              </th><th class="text-center">
                3 ตัวล่าง
              </th><th class="text-center">
                วิ่งล่าง
              </th><th class="text-center">
                3 ตัวโต๊ด
              </th><th class="text-center">
                2 ตัวบน
              </th><th class="text-center">
                3 ตัวบน
              </th><th class="text-center">
                วิ่งบน
              </th></tr>
            </thead> <tbody><tr class="">
              <th
                scope="col"
                rowspan="3"
                class="text-center align-middle"
              >
                อัตราจ่ายเริ่มต้น
              </th> <td
                rowspan="3"
                class="text-center align-middle"
              >
                <div class="custom-control custom-switch">
                  <input
                    id="__BVID__431"
                    type="checkbox"
                    class="custom-control-input is-valid"
                    value="true"
                  ><label
                    class="custom-control-label"
                    for="__BVID__431"
                  >เปิด</label>
                </div>
              </td> <td>จ่าย</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__432"
                    type="number"
                    min="0"
                    max="90"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      90
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__433"
                    type="number"
                    min="0"
                    max="145"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      145
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__434"
                    type="number"
                    min="0"
                    max="4"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      4
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__435"
                    type="number"
                    min="0"
                    max="125"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      125
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__436"
                    type="number"
                    min="0"
                    max="90"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      90
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__437"
                    type="number"
                    min="0"
                    max="750"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      750
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__438"
                    type="number"
                    min="0"
                    max="3"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      3
                    </div>
                  </div>
                </div>
              </td>
            </tr> <tr class="">
              <td>คอม</td> <td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__439"
                    type="number"
                    min="0"
                    max="8"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      8
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__440"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__441"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__442"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__443"
                    type="number"
                    min="0"
                    max="8"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      8
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__444"
                    type="number"
                    min="0"
                    max="15"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      15
                    </div>
                  </div>
                </div>
              </td><td class="py-2">
                <div
                  role="group"
                  class="input-group input-group-sm"
                >
                  <!----><input
                    id="__BVID__445"
                    type="number"
                    min="0"
                    max="12"
                    class="form-control"
                  ><div class="input-group-append">
                    <div class="input-group-text">
                      12
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="mt-1">
        <b-button
          variant="primary"
          type="submit"
          @click.prevent="validationForm"
        >
          บันทึกข้อมูล
        </b-button>
      </div>
    </b-card>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormCheckbox, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormCheckbox,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      currentTab: 0,
      tabs: ['เปิด/ปิดกลุ่มหวย', 'แบ่งหุ้น/เก็บของ', 'ข้อมูลการแทง', 'อัตราจ่าย'],
      status: false,
      setShare: false,
      name: '',
      lastname: '',
      tel: '',
      line_id: '',
      PasswordValue: '',
      passwordCon: '',
      copy_by: '',
      excess_percent: 80,
      huay_status: 0,
      userOp: [
        { title: 'Admin', value: 'admin' },
        { title: 'User', value: 'user' },
      ],
      excessOp: [
        { value: 80, text: '80%' },
        { value: 90, text: '90%' },
        { value: 100, text: '100%' },
      ],
      excess: 0,
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          alert('form submitted!')
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.active {
  background-color: #0068d0 !important;
  color: #fff !important;
}
</style>
